<template>
    <div class="main-wrapper">

        <!-- navigation -->
        <nav class="navigation scroll-bar" v-bind:class="{'nav-active': isNavbarActive, 'menu-active': isMenuActive}">
            <div class="container pl-0 pr-0">
                <div class="nav-content">
                    <div class="nav-top">
                        <router-link to="/"><div class="display1-size mr-2 ml-4 mb-1"><img :src="'/images/logo-1-new.png'" width="40"></div><span class="d-inline-block fredoka-font ls-3 fw-600 text-current-eling font-xl logo-text mb-0">Elingway </span> </router-link>
                        <a href="#" class="close-nav d-inline-block d-lg-none" @click="toggleNavbar()"><i class="ti-close bg-grey mb-4 btn-round-sm font-xssss fw-700 text-dark ml-auto mr-2 "></i></a>
                    </div>
                    <div v-for="(category, i) in menu" :key="i" class="mb-3">
                        <div v-if="category.menu.length && category.id === 1" class="nav-caption fw-600 font-xssss text-grey-500">
                            <span>List </span>Menu
                        </div>
                        <div v-if="category.menu.length && category.id !== 1" class="nav-caption fw-600 font-xssss text-grey-500">
                            Other
                        </div>
                        <ul class="mb-3">
                            <li class="logo d-none d-xl-block d-lg-block"></li>
                            <li v-for="(menu, j) in category.menu" :key="j">
                                <router-link v-if="category.id === 1" :to="menu.url" :class="{'active' : isActiveMenu(menu.url)}"  class="nav-content-bttn open-font"><i class="mr-3" :class="menu.icon"></i><span>{{menu.name}}</span></router-link>
                                <router-link v-else :to="menu.url" class="nav-content-bttn open-font h-auto pt-2 pb-2" :class="{'active' : isActiveMenu(menu.url)}"><i class="font-sm mr-3" :class="[menu.icon, {'text-grey-500' : !isActiveMenu(menu.url)}]"></i><span>{{menu.name}}</span></router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
        <!-- navigation -->
        <!-- main content -->
        <div class="main-content min-vh-100" v-bind:class="{'menu-active': isMenuActive}">
            <div class="middle-sidebar-header bg-white position-sticky">
                <button class="header-menu" @click="toggleNavbar()"></button>
                <div action="#" class="float-left header-search">
                    <div class="form-group mb-0 icon-input">
                        <i class="feather-menu font-lg text-grey-400 mt-0" @click="toggleMenu()"></i>
                        <input type="text" disabled  class="bg-transparent border-0 lh-32 pt-2 pb-2 pl-5 pr-3 font-xsss fw-500 rounded-xl w350">
                    </div>
                </div>
                <ul class="d-flex ml-auto right-menu-icon">
                    <li>
                        <notification/>
                    </li>
                    <li><router-link to="/message"><span class="dot-count bg-warning"></span><i class="feather-message-square font-xl text-current"></i></router-link></li>
                    <li>
                        <a href="javascript:void(0)" @click="toggleSidebar()" class="btn-toggle-sidebar"><img :src="user.photo" alt="..." class="w40 rounded-circle mt--1 btn-toggle-sidebar"></a>
                    </li>
                    <li></li>
                </ul>
            </div>
            <div class="middle-sidebar-bottom">
                <div class="middle-sidebar-left">
                    <router-view/>
                </div>
                <right-sidebar :personal-event="personalEvent" :is-sidebar-active="isSidebarActive" @close-sidebar="isSidebarActive = false"/>
            </div>
        </div>
        <!-- main content -->
        <app-footer @button-sidebar-clicked="toggleSidebar" :user="user"/>

        <div class="app-header-search">
            <form class="search-form">
                <div class="form-group searchbox mb-0 border-0 p-1">
                    <input type="text" class="form-control border-0" placeholder="Search...">
                    <i class="fas fa-search">

                    </i>
                    <a href="#" class="ml-1 mt-1 d-inline-block close searchbox-close">
                        <i class="ti-close font-xs"></i>
                    </a>
                </div>
            </form>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import RightSidebar from '../helpers/RightSidebar.vue'
import AppFooter from '../helpers/AppFooter.vue'
import Notification from '../helpers/Notification.vue'

export default ({
    name: 'AdminLayout',
    components: {
        RightSidebar,
        AppFooter,
        Notification
    },
    data(){
        return {
            media : process.env.VUE_APP_URL_CLOUD,
            user: {
                photo: 'https://elingway-storage.s3.ap-southeast-1.amazonaws.com/default/profile.png'
            },
            menu: {},
            isSidebarActive: false,
            isNavbarActive: false,
            isMenuActive: false,
            fullpath: this.$route.path,
            options: {
                rewind : true,
                perPage : 3,
                autoplay : true,
                arrows : false,
                pagination: false,
                gap: '1rem'
            },
            personalEvent: 0,
        }
    },
    created() {
        if(localStorage.getItem(`menu-admin`)) {
            this.menu = JSON.parse(localStorage.getItem(`menu-admin`))
        }
        this.getUser()
        this.getMenu()
    },
    methods: {
        async getUser(){
            this.user = JSON.parse(localStorage.getItem('user'))
        },
        async getMenu() {
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/owned-permission`, {
            headers: {
                Authorization: `Bearer ${localStorage.token}`
                }
            }).then((res) => {
                this.menu = res.data
                localStorage.setItem(`menu-admin`, JSON.stringify(this.menu))
            }).catch((err) => console.error(err))
        },
        onLogout() {
            axios.get(`${process.env.VUE_APP_URL_API}/logout`, {
            headers: {
                Authorization: `Bearer ${localStorage.token}`
                }
            }).then(() => {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                this.$router.push({name: 'Login'})
            }).catch((err) => console.error(err))
        },
        toggleSidebar(){
            this.isSidebarActive = !this.isSidebarActive
        },
        toggleNavbar(){
            this.isNavbarActive = !this.isNavbarActive
        },
        toggleMenu(){
            this.isMenuActive = !this.isMenuActive
        },
        isActiveMenu(path){
            var fullPath = this.fullpath
            if(fullPath.indexOf(path) !== -1){
                return true
            } else {
                return false
            }
        }
    }
})
</script>
